.spinner {
    animation: spinner .6s linear infinite;
};

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg)
    }
}